import React, { useEffect } from "react"

import api from "../../utils/api"

export default function RedirectCancel() {
  useEffect(() => {
    const inValidate = async () => {
      await api.get("/subscriptions/invalidate")
      const stripeRedirect = localStorage.stripeRedirect
      localStorage.removeItem("stripeRedirect")
      window.location.replace("/checkout" + stripeRedirect)
    }
    inValidate()
  }, [])

  return <div />
}
